.editor-style md-list-item,
.editor-style md-list-item .md-list-item-inner {
  min-height: 8px !important;
}
.editor-style .md-list-item-text h3 {
  color: #2e394e !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  letter-spacing: 0.01em !important;
}
.editor-style md-list-item .md-avatar,
.editor-style md-list-item .md-avatar-icon,
.editor-style md-list-item .md-list-item-inner .md-avatar,
.editor-style md-list-item .md-list-item-inner .md-avatar-icon {
  margin-top: 6px !important;
  margin-bottom: 2px !important;
  margin-right: 6px !important;
  border-radius: 50%;
  box-sizing: content-box;
}
.editor-style .md-list-item-text p {
  color: #acb1be !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  letter-spacing: 0.05em !important;
  margin: 0 0 0 0;
  line-height: 1.6em !important;
  font-style: italic !important;
}
.editor-style .profile-inner {
  padding: 15px;
}
.editor-style md-list-item .md-no-style {
  padding-right: 6px !important;
}
.editor-style button .md-button.md-icon-button {
  width: 20px;
}
.editor-style md-tabs[md-border-bottom] md-tabs-wrapper {
  border-width: 0 0 0 0 !important;
}
.editor-style md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-ink-bar {
  color: #F36;
  background: #F36;
}
.editor-style md-tab-item span {
  color: #2e394e;
}
.editor-style md-input-container .md-input-focused {
  color: #F36;
  background: #F36;
}
.editor-style .md-icon-button .dots {
  font-size: 23px;
  color: #acb1be;
}
.editor-style md-list-item .md-list-item-inner .md-avatar md-icon {
  font-size: 23px !important;
  color: #2e394e;
}
.editor-style md-list-item .md-list-item-inner .md-secondary md-icon {
  opacity: 0;
  color: #BDC1CC !important;
}
.editor-style md-list md-list-item button.md-button md-icon {
  font-size: 23px !important;
}
.editor-style md-list md-list-item button.md-button:not([disabled]):hover {
  border-radius: 0;
}
.editor-style md-list md-list-item button.md-button:not([disabled]):hover md-icon {
  opacity: 1;
  font-size: 23px !important;
}
.editor-style .md-button.md-fab {
  margin-left: 10px;
  background-color: #2e394e;
}
.editor-style .md-button.md-fab:not([disabled]):hover {
  background-color: #F44336;
}
.editor-style .md-chips .md-chip .md-chip-remove md-icon {
  border-radius: 50%;
  background-color: #8C8F97;
  color: #f0f0f1;
}
.editor-style .md-chips:not(.md-readonly) .md-chip {
  margin-right: 5px;
  padding: 0px 25px 0px 5px;
  border-radius: 3px;
  font-size: 90%;
  margin-bottom: 8px;
  display: inline-block;
  background-color: #555555;
  color: #efefef;
}
.editor-style .md-chips:not(.md-readonly) .md-chip:hover {
  background-color: #47506e;
  color: #F6F6F9;
}
.editor-style .md-chips:not(.md-readonly) .md-chip:hover md-icon {
  border-radius: 50%;
  background-color: #F6F6F9;
  color: #8C8F97;
}
.editor-style .md-button {
  overflow: inherit!important;
}
